<template>
    <div class="category-list">
        <div
            class="category-list__item"
            :class="{
                active: choosenCategory.id == item.id,
            }"
            v-for="(item, key) in categoryList"
            :key="key"
            @click="getTasksByCategory(item)">
            {{ item.title }}
        </div>
    </div>
</template>


<script>
import ApiTasks from '@/api/tasks';

import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            isLoading: true,
            loading: false,
            finished: false,
            choosenCategory: {},
        };
    },
    computed: {
        ...mapGetters('Category', ['categoryList']),
    },
    methods: {
        ...mapActions('Category', ['getCategoryList', 'setCategory']),
        onLoad() {
            this.params.limit += 10;
            ApiTasks.getTasksByCategory(this.params).then((responce) => {
                this.tasksList = responce.data;
                this.loading = false;
                if (this.params.type == 'new') {
                    if (responce.data.length == this.choosenCategory.tasks) {
                        this.finished = true;
                    }
                } else {
                    if (responce.data.length == this.choosenCategory.tasks_done) {
                        this.finished = true;
                    }
                }
            });
        },
        getData() {
            this.getCategoryList().then((responce) => {
                let id = this.choosenCategory.id;
                let cat = _.find(responce, (item) => {
                    return item.id == id;
                });
                if (cat) {
                    this.choosenCategory = cat;
                } else {
                    this.choosenCategory = responce[0];
                }
                this.setCategory(this.choosenCategory);
            });
        },
        getTasksByCategory(item) {
            this.setCategory(item);
            this.choosenCategory = item;
        },
    },
    mounted() {
        this.getData();
    },
};
</script>


<style lang="scss" scoped>
.category-list {
    // max-width: calc(100% - 9px);
    // // max-width: calc(100% - 48px - 9px);
    width: 100%;
    max-height: calc(100vh - 32px);
    // display: flex;
    // align-items: center;
    overflow-y: auto;
    // &::-webkit-scrollbar {
    //     width: 0;
    //     display: none;
    // }
    &__item {
        font-size: 16px;
        line-height: 19px;
        padding: 8px 16px;
        font-weight: 400;
        text-align: center;
        color: #9e9e9e;

        border: 1px solid #9e9e9e;
        border-radius: 6px;
        margin-bottom: 8px;
        cursor: pointer;

        &:hover {
            border-color: #111;
            color: #111;
        }
        &.active {
            font-weight: 500;
            background: #111111;
            border-color: #111;
            color: #ffffff;
        }
    }
}
</style>